const en = {
  "MAKE_THE": "Make The",
  "DESIGN_EASIER": "Design Easier",
  "UPLOAD_SPACE_PHOTO__CONFIGURE_RENDERING_PARAMETERS_1": "Upload Space Photo",
  "UPLOAD_SPACE_PHOTO__CONFIGURE_RENDERING_PARAMETERS_2": "Configure Rendering Parameters",
  "GET_YOUR_DESIGN": "Get Your Design",
  "FREE_TRIAL": "Free Trial",
  "ENJOY_COMPLIMENTARY_CREDITS_DAILY": "Enjoy {n} Complimentary Credits Daily",
  "YOUR_FREE_QUOTA_FOR_TODAY_HAS_BEEN_USED_UP": "You've used up your free times for today",
  "REGISTER_ACCOUNT": "Register Account",
  "CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE": "Claim 100 Professional Rendering Credits for Free!",
  "FOR_MORE_QUESTIONS_PLEASE_CONTACT": "For more questions,please contact",
  "LOGIN_IN": "LOGIN",
  "STYLE": "Style",
  "JAPANESE_DESIGN": "Japanese Design",
  "MINIMALIST": "Minimalist",
  "ART_NOUVEAU": "Art Nouveau",
  "BOHEMIAN": "Bohemian",
  "INDUSTRIAL": "Industrial",
  "SCANDINAVIAN": "Scandinavian",
  "BAROQUE": "Baroque",
  "COTTAGECORE": "Cottagecore",
  "MAXIMALIST": "Maximalist",
  "MIDCENTURY_MODERN": "Midcentury Modern",
  "NEOCLASSIC": "Neoclassic",
  "VINTAGE": "Vintage",
  "BIOPHILIC": "Biophilic",
  "TROPICAL": "Tropical",
  "ZEN": "Zen",
  "COASTAL": "Coastal",
  "FARMHOUSE": "Farmhouse",
  "FRENCH_COUNTRY_DESIGN": "French Country Design",
  "RUSTIC": "Rustic",
  "SKI_CHALET": "Ski Chalet",
  "TRIBAL": "Tribal",
  "ART_DECO": "Art Deco",
  "GAMING_ROOM": "Gaming Room",
  "CHINESE_NEW_YEAR": "Chinese New Year",
  "CHRISTMAS": "Christmas",
  "EASTER": "Easter",
  "HALLOWEEN": "Halloween",
  "CYBERPUNK": "Cyberpunk",
  "ROOM": "Room",
  "OFFICE": "Office",
  "WORKSHOP": "Workshop",
  "MEETING_ROOM": "Meeting Room",
  "COWORKING_SPACE": "Coworking Space",
  "RESTAURANT": "Restaurant",
  "COFFEE_SHOP": "Coffee Shop",
  "CLOTHING_STORE": "Clothing Store",
  "FITNESS_GYM": "Fitness Gym",
  "TOILET": "Toilet",
  "HOTEL_LOBBY": "Hotel Lobby",
  "HOTEL_ROOM": "Hotel Room",
  "HOTEL_BATHROOM": "Hotel Bathroom",
  "EXHIBITION_SPACE": "Exhibition Space",
  "ONSEN": "Onsen",
  "LIVING_ROOM": "Living Room",
  "DINING_ROOM": "Dining Room",
  "BEDROOM": "Bedroom",
  "CHILDREN_ROOM": "Children Room",
  "KITCHEN": "Kitchen",
  "BATH_ROOM": "Bath Room",
  "STUDY_ROOM": "Study Room",
  "HOME_OFFICE": "Home Office",
  "ATTIC": "Attic",
  "WALK_IN_CLOSET": "Walk In Closet",
  "MUDROOM": "Mudroom",
  "DROP_ZONE": "Drop Zone",
  "OUTDOOR_PATIO": "Outdoor Patio",
  "OUTDOOR_GARDEN": "Outdoor Garden",
  "OUTDOOR_POOL_AREA": "Outdoor Pool Area",
  "HOUSE_EXTERIOR": "House Exterior",
  "MODE": "Mode",
  "VIRTUAL_STAGING": "Virtual Staging",
  "INTERIOR_DESIGN": "Interior Design",
  "RESOLUTION": "Resolution",
  "LOW": "Low",
  "HIGH": "High",
  "GENERATE": "Generate",
  "RENDER_FAILURE_TIP:INSUFFICIENT_NUMBER_OF_RENDERINGS": "Render Failure Tip:Insufficient number of renderings",
  "RENDERING": "Rendering...",
  "CANCEL": "Cancel",
  "TIPS": "Tips",
  "DO_YOU_STILL_WANT_TO_CANCEL": "Rendering in progress. If you choose to cancel, the current rendering count will not be refunded and will still be deducted. Do you still want to cancel?",
  "CONFIRM": "Confirm",
  "CREATED_TIME": "Created Time",
  "ROOM_COWORKING_SPACE": "Room: coworking Space",
  "MODE_INTERIOR_DESIGN": "Mode: Interior Design",
  "RESOLUTION_HIGH": "Resolution: High",
  "STYLE_CYBERPUNK": "Style: Cyberpunk",
  "SHARE_LINK": "Share Link",
  "COPY": "Copy",
  "TODAY": "Today",
  "YESTERDAY": "Yesterday",
  "PREVIOUS_7_DAYS": "Previous 7 Days",
  "PREVIOUS_30_DAYS": "Previous 30 Days",
  "EARLIER": "Earlier",
  "RENDER_AGAIN": "Render Again",
  "ARE_YOU_SURE_YOU_WANT_TO_RENDER_AGAIN": "Are you sure you want to render again?",
  "TWO_FACTOR_AUTHENTICATION_ENABLED_DISABLED": "Two-Factor Authentication Enabled/Disabled",
  "ENGLISH": "English",
  "LOGOUT": "Logout",
  "PREFERENCE": "Preference",
  "AUTHENTICATION": "Authentication",
  "MEMBERS": "Members",
  "LOGIN_HISTORY": "Login History",
  "AVAILABLE_CREDIT": "Available Credit",
  "EMAIL:": "Email:",
  "PASSWORD": "Password",
  "REVISE": "Revise",
  "OLD_PASSWORD": "Old Password",
  "NEW_PASSWORD": "New Password",
  "YOUR_NEW_PASSWORD": "Your New Password",
  "CONFIRM_PASSWORD": "Confirm Password",
  "SAVE": "Save",
  "RESET": "Reset",
  "PLEASE_COMPLETE_THE_INFORMATION": "Please complete the information",
  "PLEASE_ENTER_YOUR_OLD_PASSWORD": "Please Enter Your Old Password",
  "PLEASE_ENTER_YOUR_NEW_PASSWORD": "Please Enter Your New Password",
  "PLEASE_CONFIRM_YOUR_NEW_PASSWORD": "Please Confirm Your New Password",
  "PASSWORD_COMPLEX_INFO": "Password must be 8-16 characters and contain both numbers and letters/special characters",
  "THE_TWO_PASSWORDS_YOU_ENTERED_DO_NOT_MATCH": "The two passwords you entered do not match",
  "LANGUAGE": "Language",
  "ACTIVITIES": "Activities",
  "NO_DATE": "No Date",
  "AUTOMATIC_ALLOCATION_FOR_NEW_ACCOUNT": "Automatic allocation for new account",
  "VERSION": "Version",
  "TOP_UP": "Top Up",
  "PLEASE_ENTER_INVITE_EMAIL": "Please enter invite email",
  "INVITATIONS": "Invitations",
  "THE_ENTERED_EMAIL_IS_INVALID": "The Entered Email Is Invalid",
  "EMAIL_FORMAT_INVALID": "Email format invalid, please try again after checking",
  "INVITEE_CANNOT_BE_INVITER": "Invitee cannot be inviter, please try again after checking",
  "YOUR_REFERRAL_LINK": "Your Referral Link",
  "COPY_LINK": "Copy Link",
  "TWO_FACTOR_AUTHENTICATION": "Two Factor Authentication",
  "USING_2FA": "*Enable Two-Factor Authentication on your phone or computer using a one-time password authenticator (2FA)",
  "NOTICE": "Notice",
  "PLEASE_INSTALL_A_SOFTWARE_TOKEN_INSTALLER_AUTHENTICATOR_INFO": "Please install a software token installer from your application library, such as Google Authenticator, and then scan this QR code, more information can be found in the user manual",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_1": "Can't scan the QR code?",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_2": "To add an entry manually, please provide the following information in the mobile application",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_3": "Account： greypanel:",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_4": "Secret Key：",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_5": "Auth Method：Time-based",
  "PIN_CODE": "Pin Code",
  "REGISTER_WITH_THE_DUAL_AUTHENTICATION_APPLICATION": "Register with the Dual Authentication application",
  "SEARCH": "Search",
  "INVITE_YOUR_FRIENDS": "Invite Your Friends",
  "NAME": "Name",
  "TRIGGER_TIME": "Trigger Time",
  "ACTION": "Action",
  "NO_DATA": "No data",
  "CREDITS": "Credits",
  "ASSOCIATION": "Association",
  "DISASSOCIATE": "Disassociate",
  "ASSOCIATE_MEMBERS": "Associate members",
  "RECEIVED_CREDITS": "Received credits",
  "BROWSER": "Browser",
  "ACCESS_ON": "Access On",
  "LOGIN_IP": "Login IP",
  "WELCOME_BACK_PLEASE_LOGIN_TO_YOUR_ACCOUNT": "Welcome back! Please login to your account.",
  "ACCOUNT": "Account",
  "PLEASE_ENTER_YOUR_EMAIL": "Please enter your email",
  "PLEASE_ENTER_YOUR_PASSWORD": "Please enter your password",
  "REMEMBER_ME": "Remember me",
  "FORGOT_PASSWORD": "Forgot password",
  "LOGIN": "Login",
  "HAVE_NO_ACCOUNT": "Don't have an account yet?",
  "SIGN_UP_HERE": "Sign up here.",
  "FOR_MORE_INFORMATION_ABOUT_KAINO_AI_ACCOUNT": "For more information about KainoAI accounts,",
  "CLICK_HERE": "Click here.",
  "SIGN_IN_WITH_A_THIRD_PARTY": "Sign In With A Third Party",
  "SIGN_IN_WITH_APPLE": "Sign in with Apple",
  "SIGN_IN_WITH_GOOGLE": "Sign in with Google",
  "SIGN_IN_WITH_FACEBOOK": "Sign in with Facebook",
  "PRIVACY_POLICY_PART_1": "We're committed to your privacy. KainoAI uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our",
  "PRIVACY_POLICY": "Privacy Policy",
  "LOGIN_FAILED_ERROR_MESSAGE_THE_USER_IS_NOT_ACTIVATED": "Login Failed error message:The user is not activated, please contact the administrator for assistance",
  "LOGIN_FAILED_ERROR_MESSAGE_THE_ACCOUNT_NEED_MODIFY_PASSWORD_AFTER_THIRD_PARTY_LOGIN": "Login Failed error message:The account need modify password after third party login",
  "LOGIN_FAILED_ERROR_MESSAGE_USERNAME_OR_PASSWORD_IS_INCORRECT": "Login Failed error message:Username or Password is incorrect, please try it again after checking",
  "RESET_PASSWORD": "Reset Password",
  "ENTER_THE_EMAIL_ASSOCIATED_TO_RESET_YOUR_PASSWORD": "Enter the email associated with your account and we will send an email with instructions to reset your password.",
  "EMAIL": "Email",
  "RESEND_INSTRUCTIONS": "Resend Instructions",
  "BACK": "Back",
  "PLEASE_SIGN_UP_YOUR_ACCOUNT": "Please Sign up your account.",
  "E_MAIL": "E-Mail",
  "YOUR_PASSWORD": "Your Password",
  "SIGN_UP": "Sign Up",
  "ALREADY_HAVE_AN_ACCOUNT": "Already have an account?",
  "LOGIN_HERE": "Login here.",
  "PLEASE_ENTER_VALID_EMAIL": "Please enter valid email",
  "PLEASE_CONFIRM_YOUR_PASSWORD": "Please confirm your password",
  "THE_TWO_PASSWORDS_YOU_ENTERED_DO_NOT_MATCH,_PLEASE_CHECK_AND_RESUBMIT": "The two passwords you entered do not match, please check and resubmit",
  "REGISTRATION_SUCCESSFUL": "Registration Successful!",
  "WE_HAVE_SENT_EMAIL_TO_YOU": "Thank you. We have sent email to you.",
  "PLEASE_CLICK_ON_THE_LINK_IN_THAT_EMAIL_TO_ACTIVATE_YOUR_ACCOUNT": "Please click on the link in that email to activate your account.",
  "PLEASE_CLICK_THE_BUTTON_BELOW_TO_RESEND": "If you did not receive the email, please click the button below to resend.",
  "RESEND": "Resend",
  "WELCOME_TO_LOGIN_YOUR_KAINO_AI_ACCOUNT": "Welcome to login Your KainoAI Account",
  "SUCCESSFUL_LOGIN": "Successful Login",
  "TWO_FACTOR_AUTHENTICATION_2FA": "Two-Factor Authentication (2FA)",
  "A_MESSAGE_CONTAINING_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_GOOGLE_AUTHENTICATOR": "A Message Containing A Verification Code Has Been Sent To Your Google Authenticator",
  "6_DIGIT_SECURITY_CODE_TO_CONTINUE": "Please enter a 6-digit security code to continue",
  "VERIFY": "Verify",

  "CAMERA":"Take Photos",
  "PHOTO_LIBRARY":"Photo Album",
  "PASSWORD_CANNOT_BE_THE_SAME":"The old and new passwords cannot be consistent",
  "CLOSE_ACCOUNT":"Close account",
  "SURE_TO_DELETE_ACCOUNT":"Are you sure you want to delete your account? This will permanently delete your account.",
  "DELETE_IMAGE":"Delete image",
  "SURE_TO_DELETE_IMAGE":"Are you sure you want to delete this image?",
  "SURE_TO_LOGOUT":"Are you sure you want to log out?",
  "NO_CREDIT":"The number of times has been used up",
  "NO_CREDIT_DESC": "Your free quota has been used up today. Register an account to get more free times. Do you want to register?",
  "START_RENDER":"Start",
  "ORIGINAL":"Original",
  "NEW":"New",
  "ANNOUNCEMENT":"Announcement",
  "NEW_VERSION_AVAILABLE":"New version available",
  "UPDATE":"Update",
  "ASSOCIATE_SUCCESS":"Association successful",
  "AGREE_BUILD_ASSOCIATION":"Are you sure you agree to {email}'s association application?",
  "AFTER_AGREE_ASSOCIATION":"After agreeing, you will be associated with the other organization, and the other party can assign Credits to you",
  "DISABLE_2FA":"Are you sure to turn off the two-factor authentication function?",
  "GET_LOGIN_CODE":"Get the verification code from Google Authenticator.",
  "NEXT":"Next step",
  "ENTERCODE":"Enter verification code",
  "TWOFA_ON":"Two-factor authentication is enabled",
  "ADDITIONAL_CODE": "When you sign in on a device we don't recognize, we'll ask you to enter a verification code in addition to your password.",
  "DONE":"Done",
  "AVAILABLE_CREDITS":"Available times",
  "SURPLUS_CREDIT":"Remaining times",
  "SPENT":"Used",
  "ALL":"All",
  "CHOOSE_MEMBER":"Choose a member",
  "INVITE_MEMBER":"Invite members",
  "CHOOSE_CREDITS":"Number of selections",
  "ISSUE_CREDITS":"Distributed {n} times",
  "MULTIPLES_OF_100_CAN_BE_ALLOCATED_EACH_TIME":"*The number that can be allocated each time is times 100",
  "NO_ENOUGH_CREDITS_CANNOT_ASSOCIATION":"Insufficient times remain to allocate successfully",
  "EMAIL_SENT":"Email has been sent",
  "EMAIL_SENT_DES1":"Thank you, we have sent the email",
  "EMAIL_SENT_DES2":"Please verify and change your password through your email address",
  "EMAIL_SENT_DES3":"If you did not receive the email, please click Resend",
  "RESEND_COOLING":"Resend in {n} seconds",
  "USERNAME_OR_PASSWORD_CANNOT_BE_EMPTY":"Username or email cannot be empty",
  "MAILBOX_NOT_ACTIVATED":"The mailbox is not activated, please activate it and try again",
  "SEND_VERIFICATION_EMAIL_AGAIN":"Resend verification email",
  "PRIVACY_LONG_TEXT": "We are committed to protecting your privacy. KainoAI will use the information you provide to us to contact you about our relevant content, products and services. You can unsubscribe from these messages at any time. For more information, please see our",
  "PRIVACY_LONG_LINK":"Privacy Agreement",
  "AGENT":"Agent",
  "RECEVED_CREDITS":"Number of times obtained",
  "COPY_SUCCESS":"Copied",
  "DATA_PRIVACY_MARKETING_POLICYKAINOAI":"Data Privacy and Marketing Policy - KainoAI",
  "WE_ARE_COMMITTED_TO_ENSURING_THE_PRIVACY_AND_SECURITY_OF_YOUR_PERSONAL_INFORMATION": "We are committed to ensuring the privacy and security of your personal information. This Privacy Policy outlines how we collect, use and protect your data. By using our Services, you agree to be bound by the terms and practices described here. ",
  "INFORMATION_WE_COLLECT":"1. Information we collect",
  "WE_MAY_COLLECT_THE_FOLLOWING_TYPES_OF_INFORMATION": "We may collect the following types of information:",
  "PERSONAL_INFORMATION_NAME_EMAIL_ADDRESS_CONTACT_DETAILS": "Personal information: name, email address, contact information.",
  "DESIGN_PREFERENCES_INFORMATION_YOU_PROVIDE_REGARDING_YOUR_DESIGN_NEEDS_AND_PREFERENCES": "Design Preferences: Information you provide about your design needs and preferences.",
  "USAGE_DATA_INTERACTION_DATA_SUCH_AS_YOUR_INTERACTIONS_WITH_OUR_WEBSITE_AND_DESIGN_TOOLS": "Usage Data: Interaction data, such as your interactions with our websites and design tools.",
  "WE_COLLECT_THIS_INFORMATION_TO_DELIVER_PERSONALIZED_AND_EFFICIENT_GRAPHIC_DESIGN_SOLUTIONS_TO_YOU": "We collect this information to provide you with personalized and efficient graphic design solutions.",
  "HOW_WE_USE_YOUR_INFORMATION":"2. How we use your information",
  "WE_USE_YOUR_INFORMATION_TO":"We use your information to:",
  "CREATE_AND_DELIVER_GRAPHIC_DESIGNS_BASED_ON_YOUR_PREFERENCES": "Create and deliver graphic designs based on your preferences.",
  "COMMUNICATE_WITH_YOU_ABOUT_YOUR_DESIGN_PROJECTS_AND_UPDATES": "Communicate with you about your design projects and updates.",
  "IMPROVE_OUR_SERVICES_AND_OFFERINGS_BASED_ON_USER_INTERACTIONS": "Usage Data: Interaction data, such as your interactions with our websites and design tools.",
  "DATA_SECURITY":"3. Data security",
  "WE_TAKE_THE_SECURITY_OF_YOUR_DATA_SERIOUSLY": "We take the security of your data seriously. We take technical and organizational measures to protect your information from unauthorized access, loss, misuse or alteration. However, please note that the transmission of data over the Internet is No method is completely safe.",
  "SHARING_OF_INFORMATION":"4. Information Sharing",
  "WE_DO_NOT_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_FOR_MARKETING_PURPOSES": "We do not share your personal information with third parties for marketing purposes. However, we may share your information with:",
  "OUR_SERVICE_PROVIDERS_WHO_ASSIST_US_IN_DELIVERING_OUR_DESIGN_SERVICES": "Service providers who assist us in providing design services.",
  "LEGAL_AND_REGULATORY_AUTHORITIES":"Legal and regulatory authorities as required by law.",
  "COOKIES_AND_TRACKING_TECHNOLOGIES":"5. Cookies and Tracking Technologies",
  "OUR_WEBSITE_AND_SERVICES_MAY_USE_COOKIES": "Our websites and services may use cookies and similar tracking technologies to enhance your browsing experience and collect usage information. You can adjust your browser settings to manage your cookie preferences.",
  "YOUR_CHOICES":"6. Your Choices",
  "YOU_HAVE_THE_RIGHT_TO":"You have the following rights:",
  "ACCESS_CORRECT_OR_DELETE_YOUR_PERSONAL_INFORMATION": "Access, correct, or delete your personal information.",
  "OPT_OUT_OF_RECEIVING_MARKETING_COMMUNICATIONS":"Opt out of receiving marketing communications.",
  "RAISE_OBJECTIONS_OR_RESTRICT_THE_PROCESSING_OF_YOUR_DATA": "Raise an objection or restrict data processing.",
  "TO_EXERCISE_THESE_RIGHTS_PLEASE_CONTACT_US_AT":`To exercise these rights, please contact us at $enquiry@kainoai.com. `,
  "CHILDREN_PRIVACY":"7. Children's Privacy",
  "OUR_SERVICES_ARE_NOT_INTENDED_FOR_INDIVIDUALS_UNDER_THE_AGE_OF": "Our Services are not directed to individuals under [12] years of age. We do not knowingly collect personal information from children.",
  "CHANGES_TO_PRIVACY_POLICY":"8. Changes to Privacy Policy",
  "WE_MAY_UPDATE_THIS_PRIVACY_POLICY_PERIODICALLY_TO_REFLECT_CHANGES_IN_OUR_PRACTICES_AND_SERVICES": "We may periodically update this Privacy Policy to reflect changes to our practices and services. Revisions will be posted on our website along with the effective date.",
  "FOR_ANY_INQUIRIES_ABOUT_OUR_PRIVACY_POLICY_OR_DATA_PRACTICES":`For any inquiries about our privacy policy or data practices, please contact us at enquiry@kainoai.com. Your privacy is important to us and we are committed to addressing any concerns you may have. `,
  "KAINOAI_OPERATION_TEAM":"KainoAI Operation Team",
  "CONTACT_EMAIL":"Contact email:",
  "TWOFA":"Two-factor authentication",
  "HISTORY":"History",
  "MORE":"more",
  "NOW":" ", //It is an empty character in Chinese state and translated as now in English state
  "SAVE_SUCCESS":"Saved",
  "TASK_EXECUTED_SUCCESSFULLY":"Rendering completed",
  "TASK_EXECUTION_FAILURE":"Rendering failed",
  "UUID_INVALID":"uuid is invalid",
  "RENDER_SUCCESS":"Rendering successful",
  "CANCEL_RENDER": "Rendering is in progress. If you choose to cancel, the current number of renderings will not be returned, but will still be deducted. Are you sure you want to continue the cancellation operation?",
  "SELECT_STYLE":"Please select a style",
  "SELECT_ROOM":"Please select a room type",
  "CHANGE_PICTURE":"Replace photo",
  "CHOOSE_STYLE":"Choose style",
  "CHOOSE_ROOM":"Choose a room",
  "EMPTY":"Empty...",
  "PROFILE":"Personal homepage",
  "RENDER_HISTORY":"Rendering History",
  "RENDER_DETAIL":"Rendering details",
  "PREVIEW":"Preview",
  "DELETE_CONFIRM_TEXT1":"Are you sure you want to delete the selected content? This action is irreversible.",
  "DELETE_CONFIRM_TEXT2":"Are you sure you want to delete {name}? This operation is irreversible.",
  "API_KEYS":"API keys",
  "NOT_HAVE_ANY_API_KEYS":"You currently do not have any API keys",
  "CREATE_USING_THE_BUTTON":"Use the button below to create one to get started",
  "SEARCH_NAME":"Search name",
  "CREATE_API_KEY":"Create API key",
  "OPTIONAL":"optional",
  "MY_TEST_KEY":"My test key",
  "CREATE":"Create",
  "CREATE_API_KEY_SECURITY_DESCRIPTION":"Please keep this secret key in a safe and accessible place, for security reasons you will not be able to view it again through your KainoAI account. If you lose this secret key, you will need Generate a new one.",
  "KEY":"Key",
  "LAST_USED":"Last used time",
  "SECRET_KEY": 'Secret key',
  "AUTH_METHOD": 'Authentication method',
  "SAVE_PASSWORD_2FA":'Save your AID password to Google Authenticator. If you haven\'t saved yet, return to this screen to continue with the next step. ',
  "FAIL_TO_LINK_APPLE_STORE":'Unable to connect to Apple Store',
  "DROP_AN_IMAGE": "Drop an image, tap to select",
  "RESIDENTIAL": "Residential",
  "COMMERCIAL": "Commercial",
  "UPLOAD_FAILED": "Upload Failed",
  "DELETE": "Delete",
  "GOOGLE_AUTH_DISABLED": "Two-Factor Authentication Disabled",
  "GOOGLE_AUTH_ENABLED": "Two-Factor Authentication Enabled",
  "BINDING_FAILED": "Binding Failed",
  "DOCUMENT_VERIFICATION_SUCCESSFUL": "Document Verification Successful",
  "DEAR_USER": "Dear User",
  "YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED": "Congratulations, Your Account Has Been Successfully Verified",
  "EMAIL_SENDING_FAILED": "Email Sending Failed",
  "AUTOMATICALLY_JUMP_AFTER_3_SECONDS": "Automatically Jump To The Home Page After 3 Seconds, Or Click The Button To Jump Immediately",
  "AUTOMATICALLY_JUMP_AFTER_S": "Automatic Jump",
  "JUMP_NOW": "Jump Now",
  "VERIFICATION_FAILED": "Verification Failed",
  "VERIFICATION_HAS_EXPIRED":"Some problems have occurred, and the verification has expired. We have resent the activation email to your mailbox, please check it.",
  "PARAMETER": "Parameter",
  "EMAIL_LENGTH_CANNOT_EXCEED_50_CHARACTERS": "Email length cannot exceed 50 characters!",
  "UPLOAD_PICTURE": "Upload Picture",
  "DOWNLOAD": "Download",
  "FULL": "Full view",
  "SENDING":"Sending",
  "SUCCESSFUL_DISTRIBUTION": "Successful distribution",
  "STATUS":"Status",
  "SYSTEM_MAINTENANCE_NOTICE":"System Maintenance Notice",
  "DOES_IT_JUMP_TO_THE_ANDROID_BUNDLE": "Whether to jump to Android binding?",
  "WEB_BINDING": "Web Binding",
  "ANDROID_BINDING": "Android Binding",
  "APPLY": "Apply",
  "EDIT": "Edit",
  "THE_NAME_CANNOT_BE_EMPTY": "The name cannot be empty",
  "CREATE_DATE": "Create Date",
  "ADVANCED_PROMPT": "Advanced Prompt",
  "DONT_NEED_THIS_SERVICE": "Don't need this service",
  "BAD_USE_EXPERIENCE": "Bad use experience",
  "FOUND_A_BETTER_ALTERNATIVE": "Found a better alternative",
  "REMARK": "Remark",
  "PLEASE_CHOOSE_REASON": "Please Choose Reasons",
  "OBJECT_REMOVAL": "Object Removal",
  "RENDER_FAILURE_TIP":"Render Failure Tip",
  PLEASE_RATE_YOUR_SATISFACTION_WITH_THE_RENDERING_BY_KAINOAI:"Please rate your satisfaction with the rendering by KainoAI.",
  VERY_UNSATISFIED:"Very unsatisfied",
  UNSATISFIED:"Unsatisfied",
  NEUTRAL:"Neutral",
  SATISFIED:"Satisfied",
  VERY_SATISFIED:"Very satisfied",
  SKETCH: "Sketch Drawing",
  UPLOAD:"Upload",
  ERASER:"Eraser",
  AUTO:"Auto",
  AUTO_SELECTING_AN_ITEM_BY_CLICKING_ON_IT:"Auto-selecting an item by clicking on it",
  MANUAL:"Manual",
  MANUAL_SELECTION_OF_ITEMS_BY_PAINTING:"Manual selection of items by painting",
  MULTIPLE_SELECTION:"Multiple Selection",
  AUTOMATIC_ITEM_SELECTION_THROUGH_MULTIPLE_MARKER_POINTS:"Automatic item selection through multiple marker points",
  CONFIRMATION_OF_CHOICE:"Confirmation of Choice",
  START: "Start",
  CONTRAST:"Contrast",
  REVOCATION:"Revocation",
  RECOVER:"Recover",
  BRUSH_SIZE:"Brush Size",
  DRAG_AND_DROP_THE_IMAGE_HERE_TO_UPLOAD:" Drag and drop the image here to upload",
  HD_RESTORATION:"HD Restoration",
  DESIGN:"Design",
  DETAILS: 'Details',
  RESTART: 'Restart',
  SHARE: 'Share',
  SWIPE_DOWN_TO_VIEW_MODE: 'Swipe down to view mode',
  PLEASE_WAIT:"Please wait",
  GENERATING_THE_DESIGN:"Generating the design",
  PLEASE_WAIT_A_MOVEMENT:"Please wait a moment",
  CONVERSATION: "Conversation",
}

export default en;
