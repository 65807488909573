import { VERSION_NUMBER } from "@/store/globalConfig/config";
import { message } from "antd";
import axios from "axios";
import { getLanguage } from "../react-i18next/i18n";
import accountService, { TOKEN_KEY } from "../store/account/service";

// export const API_BASE_URL = "http://ai.stream-svc.com"; // 基础API URL
export const API_BASE_URL = ""; // 基础API URL
export const APPKEY = "cVc52CuK";
export const APPSECRET = `f382bf63270183bb4885e41d81f79ea40c5dfc6a`;
export const TIMESTAMP = Date.now().toString();
/**
 * 创建一个 Axios 实例，用于发送请求
 */
const api = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? `${API_BASE_URL}/api/v1` : "/api/v1",
  timeout: 2*60*1000, // 设置请求超时时间，单位是毫秒
});


/**
 * 添加请求拦截器，用于在发送请求前设置 token
 */
api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem(TOKEN_KEY);
  config.headers.platform = "web";
  config.headers.version = VERSION_NUMBER
  const arr = ["/user/register", "/user/login"];
  if (arr.includes(config.url)) {
    return config;
  }
  config.headers.token = config.headers.token ? config.headers.token : token;
  const language = localStorage.getItem("language");
  config.headers["App-User-Locale"] = accountService.lanInfo$.value
    ? accountService.lanInfo$.value
    : language
    ? language
    : getLanguage();
  return config;
});

// const error = (content: any) => {
//   message.error(content, 2);
// };

/**
 * 添加响应拦截器，用于处理响应数据
 */
api.interceptors.response.use(
  (response:any) => {
    // 返回响应数据
    const { data } = response;
    const code: number = data.code; // 明确指定 code 的类型为 number

    // 处理不同的错误码
    if (code !== 200) {
      const redirectPaths: { [key: number]: string[] } = {
        10001: ["/registered-successfully", "/email-binding", "/login"],
        10600: ["/login-code"],
        10300: ["/login"],
        10224: ["/registered-successfully", "/email-binding", "/login"],
        10206: ["/email-binding", "/login"],
      };

      if (redirectPaths[code] && !redirectPaths[code].includes(window.location.pathname)) {
        window.location.href = `${window.location.origin}${redirectPaths[code][0]}`;
        return response.data;
      }

      if (code === 10206 && window.location.pathname !== "/email-binding" && window.location.pathname !== "/login") {
        window.location.href = `${window.location.origin}/login`;
        return response.data;
      }

      // 其他情况下返回响应数据
      message.error(response.data.msg);
      return response.data;
    }

    // 处理响应数据中的 uri 元数据
    const uri  = response.config.url;
    if (uri === "/user/detail") {
      accountService.lanInfo$.next(data.locale);
    }

    return response.data;
  },
  (error) => {
    // 处理请求错误
    if (error.response) {
      // 请求已发送并且服务器返回了错误状态码
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // 请求已发送但没有收到响应
      console.error(error.request);
    } else {
      // 发生了其他错误
      console.error("Error", error.message);
    }
    console.error(error.config);
    throw error;
  }
);


/**
 * 发送 GET 请求
 * @param {string} url 请求的 URL
 * @param {Object} params 请求参数
 * @returns {Promise} 返回一个 Promise 对象
 */
export function get(url: string, params?: any, headers: any = {}) {
  return api.get(url, {
    params: params,
    headers: {
      ...headers,
    },
  });
}
/**
 * 发送 DELETE 请求
 * @param {string} url 请求的 URL
 * @param {Object} params 请求参数
 * @returns {Promise} 返回一个 Promise 对象
 */
export function del(url: string, params?: any, headers: any = {}) {
  return api.delete(url, {
    params: params,
    headers: {
      ...headers,
    },
  });
}

/**
 * 发送 POST 请求
 * @param {string} url 请求的 URL
 * @param {Object} data 请求数据
 * @returns {Promise} 返回一个 Promise 对象
 */
export function post(url: string, data?: any, headers: any = {}) {
  return api.post(url, data, {
    headers: {
      "Content-Type": "application/json", // 默认设置为 application/json
      ...headers,
    },
  });
}

/**
 * 发送 PUT 请求
 * @param {string} url 请求的 URL
 * @param {Object} data 请求参数
 * @returns {Promise} 返回一个 Promise 对象
 */
export function put(url: string, data?: any, headers: any = {}) {
  return api.put(url, data, {
    headers: {
      "Content-Type": "application/json", // 默认设置为 application/json
      ...headers,
    },
  });
}

/**
 * 上传图片，支持传递其他参数
 *
 * @param {object} file - 待上传的图片文件
 * @param {object} data - 额外的参数，如标题、描述等
 * @returns {Promise<object>} - 返回一个 Promise 对象，包含响应数据和响应状态等信息
 */
export function uploadImage(file: any, data?: any) {
  // 创建一个 FormData 对象
  const formData = new FormData();

  // 将图片文件和其他参数添加到 FormData 中
  formData.append("file", file);
  if (data) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }

  // 发送 POST 请求
  return post("/img/upload", formData, {
    "Content-Type": "multipart/form-data",
  });
}
export function uploadImage2Type(file: any, data?: any) {
  // 创建一个 FormData 对象
  const formData = new FormData();

  // 将图片文件和其他参数添加到 FormData 中
  formData.append("file", file);
  if (data) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }

  // 发送 POST 请求
  return post("/img/hirupload", formData, {
    "Content-Type": "multipart/form-data",
  });
}
