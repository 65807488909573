import { ConfigProvider } from "antd";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import "./App.css";
import useWindowResize from "./Hooks/useWindowResize";
import { LanguageProvider } from "./components/LanguageContext";
import MobilePage from "./pages/h5";
import PCPage from "./pages/pc";
import { VERSION_NUMBER } from "./store/globalConfig/config";

function App() {
  ReactGA.initialize(VERSION_NUMBER);
  /*   const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
    document.documentElement.style.fontSize = `${rootFontSize}px`; */
  const userAgent = navigator.userAgent;

  // 检查 User-Agent 中是否包含 "SM-G965" (Galaxy S9+ 的型号)
  if (/SM-G965F|SM-G965U|SM-G965W/i.test(userAgent)) {
    document.documentElement.style.fontSize = `${12}px`;
  } else {
    const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
    document.documentElement.style.fontSize = `${rootFontSize}px`;
  }
  const handleWindowResize = () => {
    /*     const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
        document.documentElement.style.fontSize = `${rootFontSize}px`; */
    const userAgent = navigator.userAgent;

    console.log("userAgent", userAgent);
    // 检查 User-Agent 中是否包含 "SM-G965" (Galaxy S9+ 的型号)
    if (/SM-G965F|SM-G965U|SM-G965W/i.test(userAgent)) {
      document.documentElement.style.fontSize = `${12}px`;
    } else {
      const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
      document.documentElement.style.fontSize = `${rootFontSize}px`;
    }
  };
  useWindowResize(handleWindowResize);

  // useEffect(() => {
  //   const userAgent = navigator.userAgent;

  //   // 检查 User-Agent 中是否包含 "SM-G965" (Galaxy S9+ 的型号)
  //   if (/SM-G965F|SM-G965U|SM-G965W/i.test(userAgent)) {
  //     document.documentElement.style.fontSize = `${12}px`;
  //   } else {
  //     const rootFontSize = isMobile ? 16 : 16; // 判断设备类型设置根元素字体大小
  //     document.documentElement.style.fontSize = `${rootFontSize}px`;
  //   }

  //   return () => {

  //   }
  // }, [])

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#77b2d3",
          },
        }}
      >
        <LanguageProvider>
          <MobileView>
            <MobilePage />
          </MobileView>
          <BrowserView>
            <PCPage />
          </BrowserView>
        </LanguageProvider>
      </ConfigProvider>

    </div>
  );
}

export default App;
