import { useEffect } from "react";

const useWindowResize = (callback: any) => {
  useEffect(() => {
    console.log("useEffect executed");

    const handleResize = () => {
      callback?.();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [callback]);
};
export default useWindowResize;
