import { CONTACT_EMAIL } from "@/store/globalConfig/config";

export const zh_TW = {
  9999: "操作失敗",
  10000: "請求參數無效，請檢查參數後再重新請求",
  10001: "令牌無效，請檢查後重試",
  10002: "令牌為空，請檢查後重試",
  10003: "令牌已過期，請檢查後重試",
  10004: "令牌失效，請聯系管理員尋求幫助",
  10100: "無法上傳圖片，請聯系管理員尋求幫助",
  10101: "不支持圖像格式，目前只支持以下格式： JPG、JPEG、PNG",
  10102: "無法查詢渲染進度，請聯系管理員尋求幫助",
  10103: "渲染任務不存在",
  10104: "渲染圖像選擇無效",
  10200: "註冊失敗，電子郵件已被他人使用",
  10201: "無法創建用戶，請聯系管理員尋求幫助",
  10202: "電子郵件不存在，請聯系管理員尋求幫助",
  10203: "用戶名或密碼不正確，請檢查後重試",
  10204: "登錄失敗，請聯系管理員尋求幫助",
  10205: "無法更新密碼，請聯系管理員尋求幫助",
  10206: "用戶不存在",
  10207: "電子郵件格式無效，請檢查後重試",
  10208: "無法發送電子郵件，請聯系管理員尋求幫助",
  10209: "無法激活電子郵件，請聯系管理員尋求幫助",
  10211: "token已過期，email激活失敗",
  10212: "無法通過 facebook 帳戶登錄，請聯系管理員尋求幫助",
  10213: "無法通過谷歌賬戶登錄，請聯系管理員尋求幫助",
  10214: "無法執行 Oauth 請求，請聯系管理員尋求幫助",
  10215: "無法獲取雙因素驗證碼，請聯系管理員尋求幫助",
  10216: "雙因素驗證失敗，請在檢查後重試",
  10217: "雙因素驗證已禁用",
  10218: "電子郵件已激活",
  10219: "電子郵件激活令牌無效，請聯系管理員尋求幫助",
  10220: "無法渲染圖像，訂購余額不足",
  10221: "Recaptcha 驗證失敗，請檢查後重試",
  10222: "無法顯示圖像，訂閱已過期，請聯系管理員尋求幫助",
  10223: "無法重置密碼，請聯系管理員尋求幫助",
  10224: "郵箱未激活，請在激活後重試",
  10225: "登錄數據異常，請聯系管理員尋求幫助",
  10226: "用戶名太長，長度不能超過 50",
  10227: "用戶註銷失敗",
  10228: "無法登錄 Apple 帳戶，請聯系管理員尋求幫助",
  10229: "刪除用戶失敗",
  10230: "用戶未激活，請聯系管理員尋求幫助",
  10231: "第三方登錄後，賬戶需要修改密碼",
  10234: "該用戶類型不是代理人類型",
  10236: "您的登錄受限。請在 15 分鐘後重試",
  10300: "試用次數已用完,請登錄",
  10400: "無法創建訂閱，請聯系開發人員尋求幫助",
  10401: "無法創建付款方式，請聯系開發人員尋求幫助",
  10402: "無法刪除付款方式，請聯系開發人員尋求幫助",
  10500: "渲染圖像失敗，訂閱為空，請訂閱並使用",
  10501: "訂閱失敗，當前正在使用的訂閱已經存在",
  10502: "訂閱失敗，訂閱正在進行中",
  10503: "訂閱狀態異常，請聯系管理員尋求幫助",
  10504: "當前平臺不支持取消訂閱，請聯系管理員尋求幫助",
  10505: "當前平臺不支持恢復訂閱，請聯系管理員尋求幫助",
  10600: "2fa 未驗證，請驗證",
  10700: "註意模板 ID 為空，請聯系管理員尋求幫助",

  10802: "受邀请人不能為當前帳號, 請檢查後重新嘗試",
  10804: "邀請鏈接無效或者不存在, 請檢查後重新嘗試",
  10805: "邀請鏈接無效，當前賬戶的電子郵件與受邀電子郵件不匹配",
  10806: "該賬戶已與邀請者賬戶綁定",
  10808: "邀請者已達到最大綁定限製，請聯系邀請者",
  10810: "往來賬戶是代理賬戶，不能與其他代理賬戶關聯",

  11000: "平臺無效，請聯系管理員尋求幫助",
  11001: "渲染歷史不存在，請聯系管理員尋求幫助",
  HOME_PAGE: "首頁",
  RENDER: "渲染",
  SUBSCRIBE: "訂閱",
  SETTINGS: "設置",
  USER: "用戶",
  USER_INFO: "用戶信息",
  QUIT: "退出",
  AID_MAKES_AI_CHANGE_LIFE: "KainoAI讓AI改變生活",
  YOU_CAN_UPLOAD_ROOM_PHOTOS:
    "您可以上傳房間照片並設置渲染參數，KainoAI會為您生成理想的房間",
  GENERATE_MY_DREAM_ROOM: "生成我的夢想房間",
  ORIGINAL_IMAGE: "原始圖像",
  LATEST_RENDERS: "渲染",
  DROP_AN_IMAGE: "拖放圖片/點擊選擇",
  KEYWORDS: "Keywords",
  ROOM: "房間",
  MODE: "模式",
  STYLE: "風格",
  RESOLUTION: "分辨率",
  HISTORY: "歷史",
  ONLY_JPG_PNG: "只能上傳 JPG/PNG 格式的圖片。",
  TASK_EXECUTION_FAILURE: "任務執行失敗。",
  RENDERING: "渲染中...",
  UUID_INVALID: "uuid 無效",
  TOKEN: "Token",
  TOTAL: "總量",
  TOKEN_USAGE_RECORDS: "Token使用記錄",
  CREATEDATETIME: "創建時間",
  USERID: "用戶ID",
  // RENDERING: "渲染圖",
  IMAGE_GENERATION_RECORD: "圖片生成記錄",
  SUCCESSFULLY_MODIFIED: "修改成功",
  SUCCESSFULLY_SET: "設置成功",
  VERIFICATION_SUCCESSFUL: "驗證成功",
  ACCOUNT_MANAGEMENT: "賬號管理",
  CHANGE_PASSWORD: "修改密碼",
  OLD_PASSWORD: "舊密碼",
  NEW_PASSWORD: "新密碼",
  CONFIRM_PASSWORD: "確認密碼",
  YOUR_NEW_PASSWORD: "您的新密碼",
  APPLY: "应用",
  PLEASE_INPUT_YOUR_OLD_PASSWORD: "請輸入您的舊密碼",
  PLEASE_INPUT_YOUR_NEW_PASSWORD: "請輸入您的新密碼",
  THE_PASSWORD_MUST_BE_AT_LEAST_8: "密碼長度必須至少為6個字符",
  PLEASE_CONFIRM_YOUR_NEW_PASSWORD: "請確認您的新密碼",
  THE_TWO_PASSWORDS_DO_NOT_MATCH: "您輸入的兩次密碼不匹配",
  SECURITY_SETTINGS: "安全設置",
  WHETHER_TO_ENABLE_TWO_FACTOR_AUTHENTICATION: "是否開啟二次驗證:",
  SCAN_THE_QR_CODE_BELOW_TO_BIND: "掃描下方二維碼綁定",
  CHECK: "校驗",
  SYSTEM_LANGUAGE_SETTING: "系統語言設置",
  LANGUAGE: "語言",
  SIMPLIFIED_CHINESE: "簡體中文",
  ENGLISH: "英文",
  TRADITIONAL_CHINESE: "繁體中文",
  UPLOADING_SUCCESSFULLY: "上傳成功",
  UPLOAD_FAILED: "上傳失敗",
  PLEASE_UPLOAD_THE_IMAGE: "請上傳圖片",
  E_MAIL: "E-mail",
  THE_INPUT_IS_NOT_VALID_E_MAIL: "輸入的電子郵件無效",
  PLEASE_INPUT_YOUR_E_MAIL: "請輸入您的電子郵箱",
  PLEASE_INPUT_YOUR_PASSWORD: "請輸入您的密碼",
  LOGIN: "登錄",
  DO_NOT_HAVE_AN_ACCOUNT_YET: "還沒有帳戶？",
  SIGN_UP_HERE: "在此註冊",
  FOR_MORE_INFORMATION_ON_GREYPANE_ACCOUNTS: "有關 AI 帳戶的更多信息，",
  CLICK_HERE: "點擊這裏。",
  ACCOUNT_LOGIN: "帳號登錄",
  PASSWORD: "密碼",
  SIGN_UP: "註冊",
  PLEASE_CONFIRM_YOUR_PASSWORD: "請確認您的密碼",
  YOUR_PASSWORD: "你的密碼",
  ALREADY_AN_AID_ACCOUNT_HOLDER: "已經是 KainoAI 賬戶持有人？",
  LOGIN_HERE: "在此登錄。",
  EMAIL: "郵箱",
  WELCOME_TO_LOG_IN_TO_YOUR_GREYPANEL_ACCOUNT: "歡迎登錄您的Stream賬戶",
  LOGIN_SUCCESSFUL: "登錄成功",
  DUAL_AUTHENTICATION: "雙重認證 (2FA)",
  A_MESSAGE_CONTAINING: "包含驗證碼的消息已發送到您的 Google 身份驗證器",
  PLEASE_ENTER_THE_6_DIGIT_TWO_FACTOR: "請輸入6位安全碼以繼續",
  CONTINUE: "繼續",
  CANCEL: "取消",
  DOCUMENT_VERIFICATION_SUCCESSFUL: "文件驗證成功",
  DEAR_USER: "親愛的用戶",
  YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED: "恭喜，您的賬戶已成功驗證",
  CLICK_HERE_TO_LOGIN: "點擊此處登錄",
  VERIFICATION_FAILED: "驗證失敗",
  DOCUMENT_VALIDATION_FAILED: "文件驗證失敗",
  VERIFICATION_HAS_EXPIRED:
    "出現了一些問題，該驗證已過期。我們已重新發送激活郵件到 您的郵箱，請註意查收。",
  MAIL_SENT_SUCCESSFULLY: "郵件發送成功",
  EMAIL_SENDING_FAILED: "郵件發送失敗",
  AUTOMATICALLY_JUMP_AFTER_3_SECONDS:
    "3秒後自動跳轉到首頁，或者點擊按鈕立即跳轉",
  AUTOMATICALLY_JUMP_AFTER_S: "s後自動跳轉",
  JUMP_NOW: "立即跳轉",
  USE_A_THIRD_PARTY_LOGIN: "使用第三方登錄",
  LOG_IN_WITH_FACEBOOK: "使用Facebook登錄",
  LOG_IN_WITH_GOOGLE: "使用Google登錄",
  LOG_IN_WITH_APPLE: "使用Apple登錄",
  OPERATION_VALUE: "操作值",
  PREFERENCE: "偏好設置",
  AUTHENTICATION: "驗證",
  LOGIN_HISTORY: "登錄歷史",
  LAST_LOGIN_TIME: "最近登錄時間",
  GOOGLE_AUTH_DISABLED: "已關閉谷歌二次驗證",
  GOOGLE_AUTH_ENABLED: "已開啟谷歌二次驗證",
  LOG_OUT: "退出登錄",
  DELETE: "刪除",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GENERATED_RECORD:
    "你確定要刪除此條生成記錄嗎？",
  BILLING_CYCLE: "結算周期",
  USED: "已使用",
  STARTING_TIME: "起始時間",
  REGISTRATION_TIME: "登記時間",
  TWO_FACTOR_AUTHENTICATION: "雙重認證",

  ENABLE_TWO_FACTOR_AUTHENTICATION_ON_YOUR_PHONE:
    "*使用一次性密碼驗證器(2FA)在您的手機或電腦上啟用雙重認證",
  PARAMETER: "參數",
  ACTION: "操作",
  VIEW: "預覽",
  BROWSER: "瀏覽器",
  ACCESSON: "訪問時間",
  LOGIN_IP: "登錄IP",
  CREATE_NEW_PASSWORD: "創建新密碼",
  PASSWORD_RESET_SUCCESSFUL: "密碼重置成功",
  YOUR_NEW_PASSWORD_MUST_BE_DIFFERENT_FORM_PREVIOUS_USED_PASSWORDS:
    "您的新密碼必須與以前使用的密碼不同。",
  RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY: "重置密碼郵件發送成功",
  RESET_PASSWORD: "重設密碼",
  ENTER_THE_EMAIL_ASSOCIATED_WITH_YOUR_ACCOUNT:
    "輸入與您的帳戶關聯的電子郵件，我們將發送一封電子郵件，其中包含重置密碼的說明。",
  ONE_TIME_PAYMENT: "一次性付",
  MONTHLY_PAYMENT: "月付",
  QUARTERLY_PAYMENT: "季付",
  ANNUAL_PAYMENT: "年付",
  FLOOR: "地板",
  WALL: "墻壁",
  CEILING: "天花板",
  YOUR_EMAIL_ADDRESS_IS_INCOMPLETE: "您的郵件地址不完整",
  YOUR_BANK_CARD_NUMBER_IS_INCOMPLETE: "您的銀行卡卡號不完整",
  THE_EXPIRY_DATE_OF_YOUR_CARD_IS_INCOMPLETE: "您的銀行卡的到期日不完整",
  THE_SECURITY_CODE_OF_YOUR_BANK_CARD_IS_INCOMPLETE: "您的銀行卡的安全碼不完整",
  YOUR_CARD_NAME_IS_INCOMPLETE: "您的銀行卡姓名不完整",
  SUBSCRIPTION_TYPE: "訂閱類型",
  SUBSCRIPTION_STATUS: "訂閱狀態",
  SUBSCRIPTION_EXPIRATION_TIME: "訂閱到期時間",
  SUBSCRIPTION_START_TIME: "訂閱開始時間",
  AID_MAKES: "KainoAI 讓",
  AI_CHANGE_LIFE: "Ai改變生活",
  RENDERS: "渲染圖",
  UPGRADE_TO_PLUS: "升級到Plus",
  WANT_UNLIMITED_USES: "想要無限使用?",
  QUICKLY: "快速",
  TESTER: "免費試用",
  YOU_HAVE_3_FREE_TEST_OPPORTUNITIES: "您有3次免費試用機會",
  PLUS: "Plus",
  UPGRADE_TO_PLUS_UNLIMITED_USE: "升級到Plus，無限製使用",
  FOR_MORE_QUESTIONS_PLEASE_CONTACT: "如有更多問題，請聯系",
  SELECT_IMAGE: "選擇圖片",
  WELCOME_BACK_PLEASE_LOGIN_TO_YOUR_ACCOUNT: "歡迎回來!請登錄您的帳戶。",
  REMEMBER_ME: "記住我",
  FORGOT_PASSWORD: "忘記密碼",
  PLEASE_SIGN_UP_YOUR_ACCOUNT: "請註冊您的帳戶",
  ALREADY_A_AI_ACCOUNT_HOLDER: "已經是AI賬戶持有人?",
  REGISTRATION_SUCCESSFUL: "註冊成功！",
  THANK_YOU_WE_HAVE_SENT_YOU_EMAIL_TO: "謝謝。 我們已向您發送電子郵件",
  PLEASE_CLICK_ON_THE_LINK_IN_THAT_EMAIL_TO_ACTIVATE_YOUR_ACCOUNT:
    "請點擊該電子郵件中的鏈接來激活您的帳戶。",
  IF_YOU_DID_NOT_RECEIVE_THE_EMAIL_PLEASE_CLICK_THE_BUTTON_BELOW_TO_RESEND:
    "如果您沒有收到電子郵件，請點擊下面的按鈕重新發送。",
  RESEND: "重發",
  NEXT: "下一步",
  SUBSCRIBE_TO_AI_PLUS: "訂閱AI Plus",
  PER: "每",
  MONTH: "月",
  AI_PLUS_SUBSCRIPTION: "AI Plus訂閱",
  BILLED_MONTHLY: "按月計費",
  SUBTOTAL: "小計",
  TAX: "稅",
  TOTAL_DUE_TODAY: "今天截止",
  THE_NUMBER_OF_FREE_TRIALS_HAS_BEEN_USED_UP:
    "免費試用次數已用完,您可以選擇登錄後繼續生成或者回到首頁等待免費試用次數刷新",
  GO_HOME: "回到首頁",
  IMAGE_ADDRESS_HAS_BEEN_COPIED: "圖片地址已復製，分享給好友吧",
  RENDERS_IMAGE: "渲染圖像",
  SHARE_WITH: "分享",
  COPY: "復製",
  CONGRATULATIONS_YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED:
    "祝賀您，您的帳戶已成功驗證",
  AUTOMATICALLY_JUMP_TO_THE_HOME_PAGE_AFTER_3_SECONDS_OR_CLICK_THE_BUTTON_TO_JUMP_IMMEDIATELY:
    "自動跳轉到首頁後3秒，或單擊按鈕立即跳轉",
  AUTOMATIC_JUMP: "自動跳轉",
  DOCUMENT_FAILED: "Document Failed",
  SOME_PROBLEMS_HAVE_OCCURRED:
    "出現了一些問題，驗證已經驗證已經過期。我們已經將激活郵件重新發送到您的郵箱，請查收。",
  BACK: "返回",
  ORDER_PAID_SUCCESSFULLY: "訂單支付成功",
  CONGRATULATIONS_FOR_BECOMING_A_PLUS_USER: "祝賀你成為Plus用戶",
  CONGRATULATIONS_ON_OBTAINING_THE_FOLLOWING_RIGHTS: "祝賀您獲得以下權利",
  WE_WILL_EMAIL_YOU_AN_ORDER_CONFIRMATION_WITH_DETAILS_INFO:
    "我們將通過電子郵件向您確認訂單的詳細信息。",
  IF_YOU_HAVE_QUESTIONS_ABOUT_YOUR_ORDER_YOU_CAN_EMAIL_US_AT:
    "如果您對您的訂單有疑問，您可以給我們發電子郵件",
  VIEW_NOW: "現在查看",
  BILL: "賬單",
  MY_BANK: "我的銀行",
  RESUME_PLUS: "恢復Plus",
  CANCEL_PLUS: "取消Plus",
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_PLUS_YOU_WILL_LOSE:
    "您確定要取消Plus嗎，您將失去plus權益",
  SUMBIT: "Submit",
  CONFIRM_RESUME: "確認恢復",
  ARE_YOU_SURE_YOU_WANT_TO_RESUME_THE_PLUS_IT_WILL_START_BILLING_AGAIN_ON:
    "您確定要恢復 Plus 嗎？ 它將再次開始計費",
  CONFIRM: "確認",
  NOTICE: "註意",
  PLEASE_INSTALL_A_SOFTWARE_TOKEN_INSTALLER_FROM_YOUR_APPLICATION_LIBRARY:
    "請從您的應用程序庫安裝軟件令牌安裝程序，例如Google Authenticator，然後掃描此二維碼，更多信息可以在用戶手冊中找到",
  CAN_NOT_SCAN_THE_QR_CODE: "無法掃描二維碼？",
  TO_ADD_AN_ENTRY_MANUALLY: "要手動添加條目，請在移動應用程序中提供以下信息",
  SECRET_KEY: "密鑰",
  AUTH_METHOD: "認證方式",
  TIME_BASED: "基於時間",
  REGISTER_WITH_THE_DUAL_AUTHENTICATION_APPLICATION: "註冊雙重認證應用程序",
  PIN_CODE: "PIN碼",
  PROFILE_PHOTO: "頭像照片",
  USER_NAME: "用戶名",
  LOGOUT: "退出",
  PASSWORD_MUST_BE_8_16_CHARACTERS_AND_CONTAIN_BOTH_NUMBERS_AND_LETTERS_SPECIAL_CHARACTERS:
    "密碼必須為8-16個字符，並且包含數字和字母/特殊字符",
  SAVE: "保存",
  ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT: "您確定要退出登錄嗎",
  MY_RENDER: "我的渲染",
  MORE: "更多",
  ORIGINAL: "原始圖",
  SUCCESSFULLY_DELETED: "刪除成功",
  REMOVE_CARD: "移除卡",
  ADD_CARD: "添加卡",
  ARE_YOU_SURE_REMOVE_CARD: "你確定要移除卡片嗎",
  PAYMENT: "已付款",
  PENDING: "等待",
  PAYMENT_FAILED: "付款失敗",
  WELCOME_TO_LOG_IN_TO_YOUR_AI_ACCOUNT: "歡迎您登錄您的AI賬號",
  DUAL_AUTHENTICATION_2FA: "雙重身份驗證 (2FA)",
  A_MESSAGE_CONTAINING_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_GOOGLE_AUTHENTICATOR:
    "一條包含驗證碼的消息已發送至您的 Google 身份驗證器。請輸入 6 位雙因素安全碼以繼續",
  BY_PROVIDING_YOUR_CARD_DETAILS:
    "通過提供您的卡詳細信息，您允許 KainoAI 根據其條款向您的卡收取未來付款的費用",
  SECURELY_SAVE_MY_INFORMATION_FOR_1_CLICK_CHECKOUT:
    "安全保存我的信息以便一鍵結賬",
  ENTER_YOUR_PHONE_NUMBER_TO_CREATE_A_LINK_ACCOUNT:
    "輸入您的電話號碼以創建 Link 帳戶，並在 KainoAI 上以及任何接受 Link 的地方更快地付款。",
  ENTER_ADDRESS_MANUALLY: "手動輸入地址",
  I_AM_PURCHASING_AS_A_BUSINESS: "作為企業購買",
  YOU_WILL_BE_CHARGED_THE_AMOUNT_LISTED_ABOVE_EVERY_MONTH_UNTIL_YOU_CANCEL:
    "在您取消之前，我們將每月向您收取上面列出的金額。我們可能會按照使用條款中的規定調整價格。 您可以隨時取消。 訂閱即表示您同意 KainoAI 的使用條款和隱私政策",
  REGISTER: "登記",
  TERM_OF_USE_PRIVACY_POLICY: "使用期限，隱私政策",
  SOURCE_IMAGE: "源圖像",
  GENERATED_IMAGE: "生成的圖像",
  CREATION_TIME: "創建時間",
  RESEND_INSTRUCTIONS: "重新發送",
  PLAN: "計劃",
  ADD_A_CREDIT_DEBIT_CARD: "添加信用卡/借記卡",
  RESET: "重置",
  YOU_HAVE: "每天有 ",
  FREE_TEST_OPPORTUNITIES: " 次免費試用機會",
  RESOLUTION_IS_REQUIRED: "分辨率是必填項",
  ACCOUNT: "賬戶",
  EMAIL_LENGTH_CANNOT_EXCEED_50_CHARACTERS: "郵件長度不能超過50個字符!",
  NUMBER_OF_RENDERING_TIMES_AVAILABLE: "可使用渲染次數",
  TIME_DUE: "到期時間",
  RENDERING_FAILED_FREE_TIMES_HAVE_BEEN_EXHAUSTED:
    "渲染失敗，免費次數已用盡，請聯系管理員",
  MAKE_THE_DESIGN_EASIER: "讓設計變得更簡單",
  UPLOAD_SPACE_PHOTO_CONFIGURE_RENDERING_PARAMETERS:
    "上傳空間照片，配置渲染參數，生成你的專屬設計",
  GET_YOUR_DESIGN: "開始",
  FREE_TESTER: "免費試用",
  REGISTER_ACCOUNT: "註冊賬戶",
  CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE:
    "免費獲取100次專業級渲染額度!",
  DO_NOT_SELECT: "不選擇",
  VIRTUAL_STAGING: "虛擬層次",
  INTERIOR_DESIGN: "室內設計",
  FREESTYLE: "自由式",
  LIVING_ROOM: "客廳",
  BEDROOM: "臥室",
  BATH_ROOM: "浴室",
  ATTIC: "閣樓",
  KITCHEN: "廚房",
  DINING_ROOM: "餐廳",
  STUDY_ROOM: "學習室",
  HOME_OFFICE: "家庭辦公室",
  GAMING_ROOM: "遊戲廳",
  HOUSE_EXTERIOR: "房屋外觀",
  OUTDOOR_POOL_AREA: "室外遊泳池",
  OUTDOOR_PATIO: "室外露臺",
  OUTDOOR_GARDEN: "室外庭院",
  MEETING_ROOM: "會議室",
  WORKSHOP: "工作室",
  FITNESS_GYM: "健身房",
  COFFEE_SHOP: "咖啡廳",
  CLOTHING_STORE: "服裝店",
  WALK_IN_CLOSET: "壁櫥",
  TOILET: "洗手間",
  RESTAURANT: "餐廳",
  OFFICE: "辦公室",
  COWORKING_SPACE: "辦公空間",
  HOTEL_LOBBY: "酒店大廳",
  HOTEL_ROOM: "酒店客房",
  HOTEL_BATHROOM: "酒店浴室",
  EXHIBITION_SPACE: "展覽空間",
  ONSEN: "溫泉",
  MUDROOM: "儲物室",
  DROP_ZONE: "閑置區",
  EASTER: "復活節",
  MODERN: "現代",
  MINIMALIST: "極簡",
  CONTEMPORARY: "當代",
  SCANDINAVIAN: "北歐",
  INTERIOR_AI: "室內設計",
  ZEN: "禪",
  MIDCENTURY_MODERN: "中世紀現代",
  TROPICAL: "熱帶",
  BIOPHILIC: "親自然",
  INDUSTRIAL: "工業",
  ART_DECO: "裝飾藝術",
  FARMHOUSE: "農舍",
  JAPANESE_DESIGN: "日式設計",
  RUSTIC: "鄉村",
  BOHEMIAN: "波希米亞",
  COASTAL: "海岸",
  VINTAGE: "復古",
  COTTAGECORE: "別墅",
  FRENCH_COUNTRY: "法式鄉村",
  ART_NOUVEAU: "新藝術",
  CYBERPUNK: "賽博朋克",
  MAXIMALIST: "極簡",
  BAROQUE: "巴洛克",
  VAPORWAVE: "蒸汽波",
  SKI_CHALET: "滑雪小屋",
  CHRISTMAS: "聖誕節",
  SKETCH: "寫生",
  TRIBAL: "部落",
  MEDIEVAL: "中世紀",
  CHINESE_NEW_YEAR: "中國新年",
  HALLOWEEN: "萬聖節",
  NEOCLASSIC_PRO: "新古典主義",
  AVAILABLE_TOKEN: "可用令牌",
  PREVIEW: "預覽",
  STYLE_IS_REQUIRED: "風格是必填項",
  ROOM_IS_REQUIRED: "房間是必填項",
  MODE_IS_REQUIRED: "模式是必填項",
  SPARE: "剩余",
  FREQUENCY: "次",
  RE_RENDER: "重新渲染",
  DOWNLOAD_RENDERING: "下載渲染圖",
  VERSIONS: "版本",
  CANCEL_RENDERINH: "取消渲染",
  SELECT: "請選擇",
  TIP: "提示",
  CANCEL_RENDERING_TIP_TEXT:
    "正在渲染中。如果您選擇取消，當前渲染次數將不會退還，仍將被扣除。妳確定取消嗎?",
  LOGIN_FAILED_ERROR_MESSAGE: "登錄失敗錯誤提示：",
  GENERATE: "渲染圖",
  RENDER_FAILURE_TIP: "渲染失敗提示：",
  INSUFFICIENT_NUMBER_OF_RENDERINGS: "渲染次數不足",
  COPY_IMAGE_LINK: "復製圖片鏈接",
  SHARED_LINK: "共享鏈接",
  RESIDENTIAL: "住宅",
  COMMERCIAL: "商業",
  DESGIN: "設計",
  TODAY: "今天",
  YESTERDAY: "昨天",
  RECENT_7DAYS: "前7天",
  RECENT_30DAYS: "前30天",
  EARLIER: "更早之前",
  WE_ARE_COMMITTED_TO_YOUR_PRIVACY:
    "我們致力於保護您的隱私。KainoAI使用您提供給我們的信息與您聯繫，提供有關我們相關內容、產品和服務的信息。您可以隨時取消訂閱這些通訊。有關更多信息，請查閱我們的",
  PRIVACY_POLICY: "隱私政策",
  UPLOAD_PICTURE: "上傳圖片",
  CHOOSE_THE_STYLE: "選擇風格",
  CHOOSE_THE_ROOM: "選擇房間",
  CHOOSE_THE_MODE: "選擇模式",
  CHOOSE_THE_RESOLUTION: "選擇分辨率",
  PLEASE_WAIT_GENERATING_THE_DESIGN: "請稍等，正在生成設計...",
  DETAILS: "詳情",
  DOWNLOAD: "下載",
  RESTART: "重新渲染",
  SHARE: "分享",
  FULL: "全視圖",
  AVAILABLE_CREDIT: "可使用渲染次數",
  ARE_YOU_SURE_YOU_WANT_TO_RENDER_IT_AGAIN: "您確定再次渲染嗎？",
  PROFILE: "個人主頁",
  RENDER_HISTORY: "渲染歷史",
  GENERATES: "生成",
  WAIVE: "放棄",
  RENDER_AGAIN: "再次渲染",
  MEMBERS: "成員",
  ACTIVITIES: "活動",
  INVITE_YOUR_FRIENDS: "邀請您的朋友",
  CREDIT: "次數",
  DATA_PRIVACY_MARKETING_POLICYKAINOAI: "數據隱私和營銷政策 - KainoAI",
  WE_ARE_COMMITTED_TO_ENSURING_THE_PRIVACY_AND_SECURITY_OF_YOUR_PERSONAL_INFORMATION:
    "我們致力於確保您個人信息的隱私和安全。本隱私政策概述了我們如何收集、使用和保護您的數據。通過使用我們的服務，您同意接受此處描述的條款和做法。",
  INFORMATION_WE_COLLECT: "1. 我們收集的信息",
  WE_MAY_COLLECT_THE_FOLLOWING_TYPES_OF_INFORMATION:
    "我們可能收集以下類型的信息：",
  PERSONAL_INFORMATION_NAME_EMAIL_ADDRESS_CONTACT_DETAILS:
    "個人信息：姓名、電子郵件地址、聯繫方式。",
  DESIGN_PREFERENCES_INFORMATION_YOU_PROVIDE_REGARDING_YOUR_DESIGN_NEEDS_AND_PREFERENCES:
    "設計偏好：您提供的有關設計需求和偏好的信息。",
  USAGE_DATA_INTERACTION_DATA_SUCH_AS_YOUR_INTERACTIONS_WITH_OUR_WEBSITE_AND_DESIGN_TOOLS:
    "使用數據：交互數據，例如您與我們的網站和設計工具的互動。",
  WE_COLLECT_THIS_INFORMATION_TO_DELIVER_PERSONALIZED_AND_EFFICIENT_GRAPHIC_DESIGN_SOLUTIONS_TO_YOU:
    "我們收集此信息以向您提供個性化和高效的圖形設計解決方案。",
  HOW_WE_USE_YOUR_INFORMATION: "2. 我們如何使用您的信息",
  WE_USE_YOUR_INFORMATION_TO: "我們使用您的信息來：",
  CREATE_AND_DELIVER_GRAPHIC_DESIGNS_BASED_ON_YOUR_PREFERENCES:
    "基於您的偏好創建和交付圖形設計。",
  COMMUNICATE_WITH_YOU_ABOUT_YOUR_DESIGN_PROJECTS_AND_UPDATES:
    "與您溝通有關您的設計項目和更新的信息。",
  IMPROVE_OUR_SERVICES_AND_OFFERINGS_BASED_ON_USER_INTERACTIONS:
    "使用數據：交互數據，例如您與我們的網站和設計工具的互動。",
  DATA_SECURITY: "3. 數據安全性",
  WE_TAKE_THE_SECURITY_OF_YOUR_DATA_SERIOUSLY:
    "我們認真對待您數據的安全性。我們實施技術和組織措施，以保護您的信息免受未經授權的訪問、丟失、濫用或更改。然而，請註意，通過互聯網傳輸數據的任何方法都不是完全安全的。",
  SHARING_OF_INFORMATION: "4. 信息共享",
  WE_DO_NOT_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_FOR_MARKETING_PURPOSES:
    "我們不會為營銷目的與第三方分享您的個人信息。但是，我們可能與以下方分享您的信息：",
  OUR_SERVICE_PROVIDERS_WHO_ASSIST_US_IN_DELIVERING_OUR_DESIGN_SERVICES:
    "助我們提供設計服務的服務提供商。",
  LEGAL_AND_REGULATORY_AUTHORITIES: "根據法律要求的法律和監管機構。",
  COOKIES_AND_TRACKING_TECHNOLOGIES: "5. Cookies和追蹤技術",
  OUR_WEBSITE_AND_SERVICES_MAY_USE_COOKIES:
    "我們的網站和服務可能使用Cookies和類似的追蹤技術來增強您的瀏覽體驗並收集使用信息。您可以調整瀏覽器設置以管理您的Cookie偏好。",
  YOUR_CHOICES: "6. 您的選擇",
  YOU_HAVE_THE_RIGHT_TO: "您有以下權利：",
  ACCESS_CORRECT_OR_DELETE_YOUR_PERSONAL_INFORMATION:
    " 訪問、更正或刪除您的個人信息。",
  OPT_OUT_OF_RECEIVING_MARKETING_COMMUNICATIONS: "選擇不接收營銷通信。",
  RAISE_OBJECTIONS_OR_RESTRICT_THE_PROCESSING_OF_YOUR_DATA:
    "提出異議或限製數據處理。",
  TO_EXERCISE_THESE_RIGHTS_PLEASE_CONTACT_US_AT: `要行使這些權利，請通過${CONTACT_EMAIL}與我們聯繫。`,
  CHILDREN_PRIVACY: "7. 兒童隱私",
  OUR_SERVICES_ARE_NOT_INTENDED_FOR_INDIVIDUALS_UNDER_THE_AGE_OF:
    "我們的服務不面向[12]歲以下的個人。我們不會故意收集兒童的個人信息。",
  CHANGES_TO_PRIVACY_POLICY: "8. 隱私政策的更改",
  WE_MAY_UPDATE_THIS_PRIVACY_POLICY_PERIODICALLY_TO_REFLECT_CHANGES_IN_OUR_PRACTICES_AND_SERVICES:
    "我們可能定期更新本隱私政策，以反映我們的做法和服務的變化。修訂版本將與生效日期一起發布在我們的網站上。",
  FOR_ANY_INQUIRIES_ABOUT_OUR_PRIVACY_POLICY_OR_DATA_PRACTICES: `有關我們的隱私政策或數據實踐的任何查詢，請通過${CONTACT_EMAIL}與我們聯繫。您的隱私對我們很重要，我們致力於解決您可能有的任何疑慮。`,
  KAINOAI_OPERATION_TEAM: "KainoAI運營團隊",
  CONTACT_EMAIL: "聯繫電子郵件：",
  ISSUE: "發行",
  CREDITS: "關聯",
  CHOOSE_CREDITS: "選擇學分",
  INVITE_MEMBER: "邀請成員",
  TOP_UP: "充值",
  YOUR_AVAILABLE_CREDITS: "您的可用積分",
  ASSOCIATION: "協會",
  DISASSOCIATE: "解除關聯",
  MULTIPLES_OF_100_CAN_BE_ALLOCATED_EACH_TIME: "每次可分配 100 的倍數",
  INVITATIONS: "邀請",
  PLEASE_ENTER_INVITE_EMAIL: "請輸入邀請人電子郵件",
  THE_INPUT_IS_NOT_VALID_EMAIL: "輸入的電子郵件無效！",
  PLEASE_INPUT_YOUR_EMAIL: "請輸入您的電子郵件！",
  COPY_LINK: "復製鏈接",
  YOUR_REFERRAL_LINK: "您的推薦鏈接",
  DONE: "完成",
  AVAILABLE_CREDITS: "可用積分",
  SPENT: "已用",
  ALL: "全部",
  SUCCESSFUL_DISTRIBUTION: "分配成功",
  ASSOCIATED_MEMBER: "相關成員",
  RECEVED_CREDITS: "收到的積分",
  INVITATION_TO_JOIN: "邀請加入",
  CONSENT_CONFIRMED: "確定同意",
  OF_ESTABLISHING_AN_ASSOCIATED_APPLICATION: "的建立關聯申請嗎？",
  WHO_CAN_ASSIGN_CREDITS_TO_YOU:
    "同意後將與對方組織建立關聯，對方可以為你分配積分",
  CONFIRM_DELETING_THIS_RENDERING_RECORD: "確認刪除這一條渲染記錄嗎？",
  INITIALISATION_GIFT: "初始化贈送",
  THE_NUMBER_OF_TIMES_THAT_CAN_BE_ALLOCATED_CANNOT_EXCEED: "可分配次數不可超過",
  BINDING_FAILED: "綁定失敗",
  DOES_IT_JUMP_TO_THE_ANDROID_BUNDLE: "是否跳轉到Android綁定?",
  WEB_BINDING: "Web端綁定",
  ANDROID_BINDING: "Android端綁定",
  TRIGGERED_TIME: "觸發時間",
  SEARCH: "搜索",
  NAME: "用戶名",
  SURPLUS_CREDIT: "分配次數",
  NUMBER_OF_NEW_ACCOUNTS: "新賬戶次數",
  SYSTEM_MAINTENANCE_NOTICE:"系統維護通知",
  INDIVIDUAL_CENTRE:"個人中心",
  RESEND_VERIFICATION_EMAIL:"重新發送郵件",
  CREATED_SUCCESSFULLY:"創建成功",
  EDIT:"修改",
  STATUS:"狀態",
  LAST_USED:"最後使用",
  
  PLEASE_RATE_YOUR_SATISFACTION_WITH_THE_RENDERING_BY_KAINOAI:"請評價您對 KainoAI 渲染服務的滿意度。",
  VERY_UNSATISFIED:"非常不滿意",
  UNSATISFIED:"不滿意",
  NEUTRAL:"中性",
  SATISFIED:"滿意",
  VERY_SATISFIED:"非常滿意",

  UPLOAD:"上傳",
  ERASER:"擦除",
  AUTO:"AI 選區",
  AUTO_SELECTING_AN_ITEM_BY_CLICKING_ON_IT:"通過點擊讓AI自動選區",
  MANUAL:"手動選區",
  MANUAL_SELECTION_OF_ITEMS_BY_PAINTING:"通過手動選區",
  MULTIPLE_SELECTION:"多點選區",
  AUTOMATIC_ITEM_SELECTION_THROUGH_MULTIPLE_MARKER_POINTS:"通過多個標記點讓AI自動選區",
  CONFIRMATION_OF_CHOICE:"確認選擇",
  START: "開始",
  CONTRAST:"對比",
  REVOCATION:"撤銷",
  RECOVER:"恢復",
  BRUSH_SIZE:"畫筆大小"
};
